<template>
  <div class="common-layout">
    <el-container>
      <el-header>
        <el-menu
            :default-active="activeIndex"
            mode="horizontal"
            background-color="#0085FE"
            text-color="#fff"
            active-text-color="#ffd04b"
            @select="handleSelect"
            router
        >
          <el-menu-item>
            <el-image class="logo" src="/image/logo.png"></el-image>
          </el-menu-item>
          <Header :menuData="list"></Header>
        </el-menu>
      </el-header>
      <el-main>
        <router-view :bannerHeight="bannerCarouselHeight"></router-view>
      </el-main>
      <el-footer>
        <div v-if="isFooterMenu" id="bottom">
          <div class="layui-logo">
            <el-image class="logo" alt="柳林教育" src="/image/logo2.png"></el-image>
            <div class="brief-introduction">成都柳林职业技能培训学校，是西南地区一家拥有多年教学历史的专业培训机构。</div>
            <div>申请友链QQ：2295846447</div>
          </div>
          <div class="menu">
            <Footer :menuData="list"></Footer>
          </div>
        </div>
        <div class="copyright">
          <div class="layui-container">
            <p>成都柳林职业技能培训学校&nbsp;版权所有&nbsp;<a class="record-number" href="https://beian.miit.gov.cn/#/Integrated/index">蜀ICP备2021028597号-1</a></p>
            <pre>Copyright 2017-{{ year }} www.ll-edu.com All Rights Reserved</pre>
          </div>
        </div>
      </el-footer>
    </el-container>
  </div>
</template>

<script>

import Header from "@/views/layout/components/Header";
import Footer from "@/views/layout/components/Footer";
import {ref} from "vue";
import {fetchList} from "@/api/menu";

function menuTree(menuList, menu) {
  const list = []
  for (const index in menuList) {
    if (menuList[index].parentId === menu.id) {
      if (menuList[index].rgt - menuList[index].lft !== 1) {
        menuList[index].children = menuTree(menuList, menuList[index])
        list.push(menuList[index])
      } else {
        list.push(menuList[index])
      }
    }
  }
  return list
}

export default {
  name: "layoutApp",
  components: {
    Header,
    Footer
  },
  computed: {
    key() {
      return this.$route.path
    }
  },
  data(){
    return {
      list: [],
      isFooterMenu: true,
      bannerProportion: 3.17,
      bannerCarouselHeight: 600,
      year: new Date().getFullYear()
    }
  },
  created() {
    const that = this
    window.fullWidth = document.documentElement.clientWidth
    that.bannerCarouselHeight = window.fullWidth/that.bannerProportion
    that.getList()
  },
  mounted() {
    const that = this
    that.isFooterMenu = window.fullWidth >= 768;
    window.onresize = () => {
      return (() => {
        window.fullWidth = document.documentElement.clientWidth
        that.isFooterMenu = window.fullWidth >= 768;
        that.bannerCarouselHeight = window.fullWidth/that.bannerProportion
      })()
    }
  },
  methods: {
    getList(){
      fetchList(null).then(response => {
        if (response.code === 200){
          const menuList = response.data
          for (const index in response.data) {
            if (response.data[index].parentId === 0) {
              if (response.data[index].rgt - response.data[index].lft !== 1) {
                response.data[index].children = menuTree(menuList, menuList[index])
                this.list.push(response.data[index])
              } else {
                this.list.push(response.data[index])
              }
            }
          }
        }
      })
    }
  },
  setup(){
    const activeIndex = ref('1')
    const handleSelect = (key, keyPath) => {
      console.log(key, keyPath)
    }
    return { //必须返回 模板中才能使用
      activeIndex,handleSelect
    }
  }
}
</script>

<style>
body{
  margin: 0;
}
.logo {
  height: 100%;
}
.logo img {
  width: auto;
  height: 40px;
  vertical-align: middle;
}
.el-menu--horizontal{
  border-bottom: none !important;
}
#bottom{
  padding: 2rem 0;
  text-align: center;
  background-color: #E0E1E6;
}
#bottom .layui-logo{
  display: inline-block;
  color: #868A95;
  font-size: 0.8rem;
  text-align: left;
  width: 13rem;
  vertical-align: top;
  margin-right: 5rem;
}
.copyright .record-number {
  color: #999999;
}
#bottom .layui-logo .logo img{
  width: 100%;
  height: 100%;
}
#bottom .layui-logo .brief-introduction{
  color: #333;
  margin-top: 1rem;
  text-indent: 1.8rem;
  margin-bottom: 1rem;
}
#bottom .menu{
  display: inline-block;
  vertical-align: top;
}
.el-menu{
  font-weight: bold;
  text-align: center;
  overflow-x: auto; /* 设置水平滚动条 */
  overflow-y: hidden;
  display: block !important;
  white-space: nowrap;
}
.header{
  text-align: -webkit-center;
  display: -webkit-flex; /* Safari */
  display: flex;
  width: 100%;
  overflow-y: hidden;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none; /* Firefox */
  scrollbar-color: transparent transparent; /* Chrome/Safari */
}
.el-header {
  --el-header-padding: 0 !important;
  --el-header-height: aout !important;
}
.el-main {
  --el-main-padding: 0 !important;
}
.el-footer {
  --el-footer-padding: 0 !important;
  --el-footer-height: 60px;
}
.copyright {
  color: #999999;
  font-size: 1rem;
  text-align: center;
  line-height: 2rem;
  margin: 1rem auto;
}
pre{
  white-space: pre-wrap;
}
@media only screen and (min-width: 768px) {
  .copyright {
    font-size: 1rem;
  }
}
@media only screen and (min-width: 992px) {
  .copyright {
    font-size: 1rem;
  }
}
@media only screen and (min-width: 1200px) {
  .copyright {
    font-size: 1rem;
  }
}
@media only screen and (min-width: 1920px) {
  .copyright {
    font-size: 1rem;
  }
}
@media only screen and (max-width: 767px) {
  .copyright {
    font-size: 0.7rem;
  }
}
</style>
